<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">

      <v-row class="auth-row ma-0">
        <v-col
          lg="6"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/live/social.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="6"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="loginForm"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-text-field
                      v-model="email"
                      :rules="[validators.required, validators.emailValidator]"
                      :error-messages="errorMessages.email"
                      outlined
                      label="Email"
                      placeholder="email"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      :rules="[validators.required]"
                      :error-messages="errorMessages.password"
                      label="Password"
                      placeholder="Password"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <div
                      class="d-flex align-center justify-space-between flex-wrap"
                    >
                      <v-checkbox v-if="false"
                        hide-details
                        label="Remember Me"
                        class="mt-0"
                      >
                      </v-checkbox>

                      <!-- forget link -->
                      <a
                        class="ms-3 d-none"
                        href="#"
                      > Forgot Password? </a>
                    </div>

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                    >
                      Login
                    </v-btn>
                  </v-form>
                </v-card-text>

              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {mdiEyeOffOutline, mdiEyeOutline} from '@mdi/js'
import {getCurrentInstance, ref} from '@vue/composition-api'
import themeConfig from '@themeConfig'
import axios from '@axios'
import {emailValidator, required} from '@core/utils/validation'
import {useRouter} from '@core/utils'


export default {
  setup() {
    const loginForm = ref(null)

    const vm = getCurrentInstance().proxy
    const {router} = useRouter()

    const isPasswordVisible = ref(false)

    const email = ref('')
    const password = ref('')
    const errorMessages = ref([])

    const handleFormSubmit = () => {
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      axios
        .post('/auth/login', {email: email.value, password: password.value})
        .then(response => {
          const {accessToken} = response.data
          localStorage.setItem('accessToken', accessToken)
          return response
        })
        .then(() => {
          axios.get('/me').then(response => {
            const user = response.data.data
            const {ability: userAbility} = user

            // Set user ability
            // ? https://casl.js.org/v5/en/guide/intro#update-rules
            vm.$ability.update(userAbility)

            localStorage.setItem('userAbility', JSON.stringify(userAbility))
            delete user.ability
            localStorage.setItem('userData', JSON.stringify(user))
            router.push({ name: 'content-articles-index' })
          })
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          console.error('Oops, Unable to login!')
          console.log('error :>> ', error.response)
          errorMessages.value = error.response.data.errors
        })
    }

    return {
      handleFormSubmit,

      isPasswordVisible,
      email,
      password,
      errorMessages,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      validators: {
        required,
        emailValidator,
      },

      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      loginForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
